import React, { useEffect } from "react";
import VideoPlayer from './VideoPlayer';
import axios from "axios";
import './App.css';

function App() {

  useEffect(() => {
    try {
      axios({
        method: "get",
        baseURL: "https://d-96-demo-425d4fdd700a.herokuapp.com" + "/common/get-products"
      }).then((res) => {
      }).catch((err) => {
        console.log(err)
      });
    } catch (err) {
      throw err;
    }
  }, []);
  

  return (
    <div className="App">
      <VideoPlayer videoUrl={true} />
      <div style={{display: "flex", flexDirection: "column", alignItems: "start", padding: 20}}>
        <div style={{marginBottom: 10, color: "white"}}><a style={{color: "white", fontWeight: 600, textDecoration: "none"}} href="https://client.d-96.com/" target="_blank">👉 Click here to open the customer website. 🌐</a></div>
        <div style={{marginBottom: 10, color: "white"}}><a style={{color: "white", fontWeight: 600, textDecoration: "none"}} href="https://admin.d-96.com/" target="_blank">👉 Click here to open the admin website. 🌐</a></div>
        {/* <div style={{marginBottom: 10, color: "white"}}><a style={{color: "white", fontWeight: 600, textDecoration: "none"}} href="https://client.d-96.com/" target="_blank">👉 Click here to open the Ios application. 🌐</a></div> */}
        {/* <div style={{marginBottom: 10, color: "white"}}><a style={{color: "white", fontWeight: 600, textDecoration: "none"}} href="https://admin.d-96.com/" target="_blank">👉 Click here to open the admin website. 🌐</a></div> */}
        <div style={{marginBottom: 10, color: "white"}}>
          Need assistance?{' '}
          Contact us via email - <span style={{fontWeight: 600}}>support@d-96.com</span>
      </div>
      </div>
    </div>
  );
}

export default App;
