import React from 'react';
import ReactPlayer from 'react-player';

const VideoPlayer = ({ videoUrl }) => {
  return (
    <div style={{ width: '100%', overflow: 'hidden' }}>
      <ReactPlayer 
      url={"https://firebasestorage.googleapis.com/v0/b/d96-front.appspot.com/o/D96-1.mp4?alt=media&token=ac497ccd-4811-4c33-818f-60e3c6ca9362"} 
      controls={true}
      playing={true}
      width="100%"
      height="80vh"
       />
    </div>
  );
};

export default VideoPlayer;
